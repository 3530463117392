<template>
  <div class="container" id="content">
    <div class="headLine">
      <div>
        <el-button type="primary" size="small" @click="addOption"
          >新增</el-button
        >
      </div>
    </div>
    <div class="list_box">
      <div
        class="list_item"
        v-for="item in tableData"
        :key="item.id"
        @click="checkDetail(item)"
      >
        <div class="list_top">
          <el-image
            class="headImg"
            :src="item.inputImg"
            fit="cover"
          ></el-image>
          <div class="itemMess">
            <div class="itemLine">
              <div style="min-width: 60px">产品名称:</div>
              <div class="text_ell">{{ item.cpmc }}</div>
            </div>
            <div class="itemLine">
              <div style="min-width: 60px">规格:</div>
              <div class="text_ell">{{ item.spec }}</div>
            </div>
            <div class="itemLine">
              <div style="min-width: 60px">备注:</div>
              <div class="text_ell">{{ item.remarks }}</div>
            </div>
          </div>
        </div>
        <div class="list_ctrol">
          <el-button
            v-for="(m, mindex) in rowBtns"
            :key="mindex"
            :type="m.type"
            :size="m.size"
            :icon="m.icon"
            @click.stop="m.handler(item)"
            >{{ m.label }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="block pagingBox">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[30]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <UpdataModal
      ref="updata"
      :showFlag="showFlag"
      :messageBox="messageBox"
      :formTitle="formTitle"
      @close="closeModal"
      @success="refresh"
      :record="addForm"
    ></UpdataModal>

    <DetailModal
      :visiable="checkDetailVisiable"
      @close="closeModal"
      :data="detailData"
    ></DetailModal>
  </div>
</template>
<script>
import UpdataModal from "./modal/updata";
import DetailModal from "./modal/detail.vue";

export default {
  name: "lawsAReg",
  components: { UpdataModal, DetailModal },
  data() {
    return {
      loading: false,
      ids: 0,
      addForm: {},
      page: 0,
      size: 30,
      total: 0,
      tableData: [],
      rowBtns: [
        {
          label: "编辑",
          type: "primary",
          size: "small",
          icon: "el-icon-edit",
          handler: (row) => {
            this.edits(row);
          },
        },
        {
          label: "删除",
          type: "danger",
          size: "small",
          icon: "el-icon-delete",
          handler: (row) => {
            this.deletes(row.id);
          },
        },
      ],

      formTitle: "",
      messageBox: false,
      showFlag: false,
      token: "",
      filesList: [],

      detailData: false,
      checkDetailVisiable: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));
  },
  methods: {
    getList() {
      this.$get("/pro09/chanpin/page", {
        page: this.page,
        size: this.size,
      }).then(async (res) => {
        if (res.data.state == "success") {
          // newVal.headImg
          let data = res.data.data;
          for (let i = 0; i < data.length; i++) {
            if (i == data.length - 1) {
              this.tableData = data;
              this.total = res.data.size;
              if (this.tableData.length == 0 && this.page > 0) {
                this.page = 0;
                this.getList();
              }
            }
          }
        }
      });
    },

    // 获取头像图片
    getHeadImg(headImgName) {
      return new Promise(async (resolve, reject) => {
        let res = await this.$getBlob("/fileops/show", {
          fileName: headImgName,
        });

        if (res.config && res.config.params && res.config.params.fileName) {
          let fileNames = res.config.params.fileName;
          let fileData = fileNames.split(".")[1];
          let blob = new Blob([res.data], { type: "image/" + fileData });
          let url = window.URL.createObjectURL(blob);
          resolve(url);
        } else {
          reject();
        }
      });
    },


    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.addForm = JSON.parse(JSON.stringify(row));
      this.messageBox = true;
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/inputInfo/delete/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },

    // 新增
    addOption() {
      this.formTitle = "新增";
      this.checkDetailVisiable = false;
      this.messageBox = true;
      this.showFlag = true;

      this.$nextTick(() => {
        this.addForm = {
          // title: 'biaoti'
        };
        this.$refs.updata.resetForm();
      });
    },


    refresh() {
      this.messageBox = false;
      this.getList();
    },

    closeModal() {
      this.messageBox = false;
      this.checkDetailVisiable = false;
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },

    // 查看详情
    checkDetail(data) {
      this.messageBox = false;
      this.checkDetailVisiable = true;
      this.detailData = data;
    },
  },
};
</script>

<style scoped>
.list_box {
  height: 80vh;
  max-height: 80vh;
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  overflow-y: scroll;
}
.list_item {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 32.33%;
  margin-right: 1%;
  height: 200px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.list_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.itemMess {
  width: calc(100% - 120px);
}

.itemLine {
  width: 100%;
  display: flex;
}

.list_ctrol {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 10px;
}
.headLine {
  width: 100%;
  display: flex;
  align-items: center;
  /*justify-content: flex-end;*/
}

.pagingBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_ell {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headImg {
  width: 100px;
  height: 100px;
}
</style>
